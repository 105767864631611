.app {
  min-height: 100vh;
  background-color: rgb(160 189 174 / 30%);
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Paytone One", sans-serif;
}

.header {
  position: relative;
  z-index: 9;
}

.hero {
  position: relative;
  min-height: calc(100vh - 100px) !important;
  /* animation: fadein 0.8s; */
  background-image: url("./images/slanted-gradient.svg");
  background-size: cover;
}

.hero-wrapper {
  position: relative;
  justify-content: center;
  z-index: 2;
}

.hero-content {
  z-index: 1;
}

.logo-text {
  white-space: nowrap;
}

.login-button {
  border: 3px solid #00739d !important;
  background-color: #00739d;
  color: white;
  font-weight: bold !important;
}

.login-button:hover {
  background-color: #005c7e;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 65%);
  z-index: 0;
}

.logo {
  margin-top: 5px;
  height: 2.2em;
  width: 2.2em;
  margin-right: 5px;
}
.logo svg {
  fill: white !important;
}

button {
  width: 100%;
  font-weight: bold !important;
}

button.small,
.date-input button {
  width: auto;
}

table.scorecard thead th {
  background-color: #00739d;
  color: white;
  border: 1px solid white;
}

table.scorecard tbody th {
  padding: 2px 4px;
}

table.scorecard tbody input {
  border: none;
  padding: 14px 12px;
  width: 100%;
}

table.scorecard tbody input.scorecard-player {
  width: 100px;
}

table.games-table th:nth-of-type(1) span {
  white-space: nowrap;
}

table.games-table th:nth-of-type(2) span {
  min-width: 85px;
}

.card {
  border: 2px solid #aaaaaa;
}

.custom-dateinput {
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  border: none;
  -webkit-appearance: none;
  background: transparent;
  color: inherit;
  width: 100%;
  padding: 11px;
  font-weight: 600;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 4px;
}

.custom-dateinput:focus {
  outline: none;
  box-shadow: 0 0 2px 2px #6fffb0;
}

.checkbox input + div {
  border: solid 2px white;
  height: 20px;
  width: 20px;
}

.checkbox input + div svg {
  stroke: white;
}

input {
  background-color: white !important;
}

button.icon-button {
  border: none;
  padding: 0;
}

button.icon-button:hover {
  box-shadow: none;
}

.overview-diff-days {
  color: #ca0000;
  font-size: 1.5em;
}

@media only screen and (max-width: 600px) {
  table.scorecard tbody th {
    padding: 2px 4px;
  }

  table.scorecard tbody input {
    margin-left: 0;
  }

  .logo {
    display: none;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@font-face {
  font-family: Paytone One;
  src: url("./fonts/PaytoneOne-Regular.ttf");
}
